<!-- eslint-disable -->
<template>
  <v-container id="dashboard" fluid tag="section" v-if="hasData">
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <div></div>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <base-material-stats-card
          color="#2196f3"
          icon="mdi-sale"
          title="Patrimonio Ativo"
          :value="'R$ ' + formatPrice(totalActive)"
          sub-icon="mdi-home-map-marker"
          cardColor="#2196f3"
        />
      </v-col>

      <v-col cols="12" md="6">
        <base-material-stats-card
          color="#FF6D00"
          icon="mdi-currency-usd-off"
          title="Total Inativado"
          :value="'R$ ' + formatPrice(totalInactive)"
          sub-icon="mdi-currency-usd-off"
          cardColor="#FF6D00"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <template>
          <v-card color="dark" v-if="hasData" class="dash-card">
            <BarChart :chartData="chartData.credits" :options="options" />
            <v-card-actions>
              <v-card-subtitle class="overline" v-text="'Patrimonio Ativo'" />
            </v-card-actions>
          </v-card>
        </template>
      </v-col>

      <v-col cols="12" md="6">
        <template>
          <v-card color="dark" v-if="hasData" class="dash-card">
            <BarChart :chartData="chartData.inactive" :options="options" />
            <v-card-actions>
              <v-card-subtitle class="overline" v-text="'Patrimonio Inativo'" />
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="image, article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="image, article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="image, article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import http from "@/services/http";
import moment from "moment";
import BarChart from "@/components/component/BarChart";
import PageHeader from "@/components/core/PageHeader.vue";
import PieChart from "@/components/component/PieChart";
import BaseMaterialStatsCard from "@/components/component/MaterialStatsCard.vue";
export default {
  name: "DashboardDashboard",
  components: { BarChart, PageHeader, PieChart, BaseMaterialStatsCard },
  data() {
    return {
      hasPermission: false,
      title: "Dashboard",
      startDate: moment().startOf("month").format("yyyy-MM-DD"),
      endDate: moment().endOf("month").format("yyyy-MM-DD"),
      menu: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Dahsboard",
          disabled: true,
          href: "/",
        },
      ],
      hasData: false,
      companies: "",
      countMembers: 0,
      countCells: 0,
      totalActive: 0,
      totalInactive: 0,
      countConversion: 0,
      countInactiveMembers: 0,
      totalDebits: 0.0,
      totalCredits: 0.0,
      balance: 0.0,
      movements: [],
      chartMonth: [],
      chartData: {},
      chartResults: {},
      result: {
        payable: 0,
        receivable: 0,
        balance: 0,
        sale: 0,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                display: true,
                min: 0,
              },
              gridLines: {
                display: true,
                drawBorder: false,
              },
            },
          ],
        },
      },
      pieOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                display: false,
                min: 0,
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      },

      tabs: 0,
    };
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getMovements() {
      http.get("finance/transactions?limit=10&sort=dueDate,DESC").then((data) => {
        this.movements = data.data;
      });
    },

    selectMoth(month) {
      switch (month) {
        case 1:
          return "Janeiro R$";
          break;
        case 2:
          return "Fevereiro R$";
          break;
        case 3:
          return "Março R$";
          break;
        case 4:
          return "Abril R$";
          break;
        case 11:
          return "Novembro R$";
          break;
        case 12:
          return "Dezembro R$";
          break;
        default:
          break;
      }
    },
    async fetchResults() {
      this.hasData = false;
      await http.get(`assets/report/patrimony`).then(
        async (data) => {
          this.movements = await data.data.active;
          this.chartData.credits = await {
            labels: data.data.active.map((d) => d.type),
            datasets: [
              {
                label: "Patrimonio Ativo por Tipo R$",
                backgroundColor: [
                  "#008080",
                  "#5F9EA0",
                  "#20B2AA",
                  "#48D1CC",
                  "#40E0D0",
                  "#00CED1",
                  "#5F9EA0",
                  "#00FFFF",
                  "#2F4F4F",
                  "#8FBC8F",
                  "#5F9EA0",
                  "#20B2AA",
                ],
                data: data.data.active.map((d) => d.inventoryPrice),
              },
            ],
          };
          this.chartData.inactive = await {
            labels: data.data.inactive.map((d) => d.type),
            datasets: [
              {
                label: "Patrimonio Inativo por Tipo R$",
                backgroundColor: [
                  "#800000",
                  "#8B0000",
                  "#B22222",
                  "#A52A2A",
                  "#FF0000",
                  "#A52A2A",
                  "#800000",
                  "#B22222",
                  "#FF4500",
                  "#DC143C",
                  "#B22222",
                  "#FF0000",
                ],
                data: data.data.inactive.map((d) => d.inventoryPrice),
              },
            ],
          };
          this.totalActive = data.data.amountActive;
          this.totalInactive = data.data.amountInactive;
          this.hasData = true;
        },
        () => (this.hasData = true)
      );
    },

    async changeAll() {
      await this.fetchResults();
    },
  },
  async mounted() {
    await this.changeAll();
  },
};
</script>
<style scoped>
.text-white {
  color: white !important;
}
.dash-card {
  padding-top: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 10px !important;
}
</style>
